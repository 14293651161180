<script setup>
import {useApp} from "../../stores/app.ts";
import SaleHeader from "../../components/global/SaleHeader.vue";
import VendorBlock from "../../components/sales/VendorBlock.vue";
import {computed, onMounted, reactive, ref} from "vue";
import InputText from "../../components/forms/InputText.vue";
import LeadsTabs from "../../components/global/LeadsTabs.vue";
import useSignup from "../../composables/useSignup.js";
import InputSelect from "../../components/forms/InputSelect.vue";
import ErrorBar from "../../components/global/ErrorBar.vue";
import router from "../../router.js";
import {useRoute} from "vue-router";
import LeadsLoader from "../../components/global/LeadsLoader.vue";
import {useLeadStore} from "../../stores/lead";
import general from "../../composables/signup/modules/general.js";
import * as Sentry from "@sentry/vue"

const lead_store = useLeadStore()
const {user, selectedProvider, place, countries, getCountries, createClientContact, setUser, login, me, solution, setContactMethod, updateToken, setResponse, resetPassword} = useSignup()
const location = ref()
const route = useRoute()
const options = ref([])
const app = useApp()
const tabs = [{label: "SA ID", value: "id_number", selected: true}, {label: "Passport", value: "passport"}]
const is_loading = ref(false)
const disabled = computed(() => {
    return !form.first_name || !form.last_name || !form.email || !form.identity_type || !form.id_number || !form.cell_number || (form.identity_type === 'passport' && !form.passport_country_code) || !preferred_contact_method.value
})
const preferred_contact_method = ref("");
const error = ref('')
const form = reactive({
    first_name: "",
    last_name: "",
    cell_number: "",
    identity_type: "",
    id_number: "",
    passport_country_code: "",
    password: "",
    email: "",
    company_name: "",
    vat_number: "",
    tel_number: ""
})
const submit = async () => {
    if (form.identity_type === 'id_number' && (form.id_number === '' || !form.id_number.match(/(\d{2}((0[13578]|1[02])(0[1-9]|[12]\d|3[01])|(0[13456789]|1[012])(0[1-9]|[12]\d|30)|02(0[1-9]|1\d|2[0-8]))|([02468][048]|[13579][26])0229)(( |-)(\d{4})( |-)(\d{3})|(\d{7}))/))) {
        error.value = "Please fill in a valid South African ID Number";
    } else {
        is_loading.value = true
        error.value = ""
         if (form.identity_type === 'id_number')
            form.passport_country_code = "ZAF"

        await createClientContact(form).then(async () => {
            setUser(form)

            await login({username: form.email, password: form.password}).then(async (response) => {
                await resetPassword(form.email).catch(e => {
                    Sentry.withScope((scope) => {
                      scope.setContext("request_payload", form.email);
                      Sentry.captureException(e);
                    });
                })
                updateToken(response.token)
                setContactMethod(preferred_contact_method.value)

                await me().then(async res => {
                    setUser(res.client_contact)
                    location.value = await general.mapsToLocation({place: place.value, coordinates: {lat: place.value.geometry.location.lat(), lng: place.value.geometry.location.lng()} })
                    const prospect = {
                        first_name:         user.value.first_name,
                        last_name:          user.value.last_name,
                        email:              user.value.email,
                        cell_number:        user.value.cell_number,
                        id_type:            user.value.passport_country.code === 'ZAF' ? 'South African ID' : 'Passport',
                        id_number:          user.value.id_number,
                        contact_method:     preferred_contact_method.value ?? 'whatsapp',
                        ccid:               user.value.id
                    }
                    await lead_store.storeProspect(prospect).then(async () => {
                        const lead = {
                            address: {
                                street_number:              location.value.streetNumber,
                                street_name:                location.value.streetName,
                                suburb:                     location.value.suburb,
                                city:                       location.value.city,
                                province:                   location.value.province,
                                postal_code:                location.value.postalCode,
                            },
                            meta:               {},
                            vendor:             selectedProvider.value.vendor.slug,
                            solution:           solution.value.id.toString(),
                            prospect_id:        lead_store.prospect.id,
                        }

                        await lead_store.storeLead(lead).then(async () => {
                            if(route.path.includes('lead')) {
                                setResponse(true)
                                await router.replace('/lead/response')
                            }
                            else {
                                await router.push('/sale/location-details')
                            }
                        }).catch(async (e) => {
                            Sentry.withScope((scope) => {
                              scope.setContext("request_payload", lead);
                              Sentry.captureException(e);
                            });
                            if(route.path.includes('lead')) {
                                setResponse(false)
                                await router.push('/lead/response')
                            }
                            else {
                                error.value = e.response?.data?.message ?? 'Ooops something went wrong'
                                is_loading.value = false
                            }
                        })
                    }).catch(async (e) => {
                        Sentry.withScope((scope) => {
                              scope.setContext("request_payload", prospect);
                              Sentry.captureException(e);
                        });
                        if(route.path.includes('lead')) {
                            setResponse(false)
                            await router.push('/lead/response')
                        }
                        else {
                            error.value = e.response?.data?.message ?? 'Ooops something went wrong'
                            is_loading.value = false
                        }
                    })
                }).catch((e) =>
                {
                    if(e.response.status === 511){
                        setUser(e.response?.data?.otp_details?.methods[0]?.destination)
                        router.replace(route.path.includes('lead') ? '/lead/otp' : '/sale/otp')
                    }
                    Sentry.captureException(e)
                    error.value = e.response?.data?.message ?? 'Ooops something went wrong'
                    is_loading.value = false
                })
            }).catch((e) => {
                Sentry.withScope((scope) => {
                      scope.setContext("request_payload", {username: form.email, password: form.password});
                      Sentry.captureException(e);
                });
                error.value = e.response?.data?.message ?? 'Ooops something went wrong'
                is_loading.value = false
            })
        }).catch((e) => {
            Sentry.withScope((scope) => {
              scope.setContext("request_payload", form);
              Sentry.captureException(e);
            });
            error.value = e.response?.data?.error_slug === "user_exists" ? "An account with these details already exists. Please log in or reset your password." : e.response?.data?.message ?? 'Ooops something went wrong'
            is_loading.value = false
        })
    }
}

onMounted(async () => {
    app._showNav = false

    if(!solution.value)
        router.replace(route.path.includes('lead') ? '/lead/search' : '/sale/search')

    // Getting countries
    await getCountries();
    countries.value.forEach(country =>
        options.value.push({
            id: country.code,
            name: country.name,
        })
    )

    // Generating a password
    const uppercase = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const lowercase = 'abcdefghijklmnopqrstuvwxyz';
    const numbers = '0123456789';
    const specialCharacters = '!@#$%^&*()_+[]{}|;:,.<>?';
    const allCharacters = uppercase + lowercase + numbers + specialCharacters;

    for (let i = 0; i < 12; i++) {
        const randomIndex = Math.floor(Math.random() * allCharacters.length);
        form.password += allCharacters[randomIndex];
    }

})
</script>

<template>
<sale-header title="Personal information."/>
<main class="container --sale-lead-form-container">
    <vendor-block />
    <div class="package-block">
        <form class="package-form">
            <section>
                <div class="personal-information-header">
                    <p>Already have an account? <router-link :to="route.path.includes('lead') ? '/lead/login' : '/sale/login'">Log in</router-link></p>
                </div>
                <div class="form-columns">
                    <div class="form-row">
                        <input-text type="text" v-model="form.first_name" label="First name" :required="true" variant="secondary"/>
                        <input-text type="text" v-model="form.last_name" label="Last name" :required="true" variant="secondary"/>
                    </div>
                    <div class="form-row">
                        <input-text type="email" v-model="form.email" label="Email address" :required="true" variant="secondary"/>
                        <input-text type="tel" v-model="form.cell_number" label="Cellphone Number" :required="true" variant="secondary"/>
                    </div>
                    <div class="form-row">
                        <leads-tabs :tabs v-model="form.identity_type" />
                        <input-text type="text" v-if="form.identity_type === 'id_number'" v-model="form.id_number" label="SA ID Number" :required="true" variant="secondary"/>
                        <div class="form-columns" v-else>
                            <input-select :options v-model="form.passport_country_code" label="Country" />
                            <input-text type="text" v-model="form.id_number" label="Passport Number" :required="true" variant="secondary"/>
                        </div>
                    </div>
                    <div class="radio-group-row">
                        <p class="radio-group-label">Preferred contact method</p>
                        <div class="radio-group-container">
                            <div class="radio-group">
                                <ah-icon :name="preferred_contact_method === 'whatsapp' ? 'radio-selected' : 'radio-deselected'"
                                         :color="preferred_contact_method === 'whatsapp' ? '#00859B' : '#768692'"
                                />
                                <input type="radio" name="contact_method" value="whatsapp" id="whatsapp" v-model="preferred_contact_method"/>
                                <label for="whatsapp">WhatsApp</label>
                            </div>
                            <div class="radio-group">
                                <ah-icon :name="preferred_contact_method === 'email' ? 'radio-selected' : 'radio-deselected'"
                                        :color="preferred_contact_method === 'email' ? '#00859B' : '#768692'"
                                />
                                <input type="radio" name="contact_method" value="email" id="email" v-model="preferred_contact_method"/> <label
                                for="email">Email</label>
                            </div>
                            <div class="radio-group">
                                <ah-icon :name="preferred_contact_method === 'call' ? 'radio-selected' : 'radio-deselected'"
                                        :color="preferred_contact_method === 'call' ? '#00859B' : '#768692'"
                                />
                                <input type="radio" name="contact_method" value="call" id="call" v-model="preferred_contact_method"/> <label
                                for="call">Call</label>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <div class="button-container">
                <error-bar :error />

                <button class="button --primary" :class="{'--disabled':disabled || is_loading}" :disabled='disabled || is_loading' @click.prevent="submit" type="submit">
                    <leads-loader type="secondary" v-show="is_loading" />
                    {{ !is_loading ? 'Submit' : '' }}
                </button>
            </div>

        </form>
    </div>
</main>
</template>

<style scoped lang="scss">
.personal-information-header {
    border-radius: 2.4rem;
    background: #EFF2F5;
    border: .1rem solid var(--dew);
    height: 6rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 4rem;

    p {
        @include font(1.2,1.8,bold);
        color: var(--thunder);

        a {
            text-decoration: underline;
            color: var(--thunder);
        }
    }

    @include mobile() {
        margin-bottom: 2rem;
    }
}

.package-form {
    .button-container {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        @media (max-width: 820px) {
            gap: 15.3rem;
            button {
                margin-bottom: 0;
            }

        }

        @include mobile() {
            gap: 4rem;

            .error-bar {
                margin-bottom: 0;
            }
        }
    }
}
</style>
